export default {
  auth: {
    login: 'Login',
    logout: 'Logout',
    no_match: 'Your passwords do not match',
    forgot: {
      title: 'Forgot password',
      instruction: 'Enter your email address to receive an email how to create a new password.',
      confirmation: 'A new password has been send to your email.',
      submit: 'Recover',
    },
    reset: {
      title: 'Reset password',
      description: 'Create a new password.',
      submit: 'Reset',
    },
    submitted: {
      success: 'Done!',
      sent: 'We have sent you  email to {email}.',
      again: 'Log in again',
    },
    required: {
      email: 'Invalid email',
      password: 'Password is required',
      default: 'Required',
      name: 'Invalid name',
      url: 'Invalid url',
      number: 'Only numbers allowed',
      passwordRules: {
        lowercase: 'One lowercase letter required',
        uppercase: 'One uppercase letter required',
        length: '8 characters minimum',
        number: 'One number required',
        special: 'One special character required',
      },
    },
  },
  actions: {
    back: 'Back',
    next: 'Next',
    add: 'Add',
    edit: 'Edit',
    save: 'Save',
    create: 'Create',
    close: 'Close',
    esc: 'esc',
  },
  labels: {
    hourType: 'Hour type',
    project: 'Project',
    description: 'Description',
    employee: 'Employee',
    client: 'Client',
  },
  event: {
    title: 'Hour registration',
    dialog: {
      create: 'Create',
      edit: 'Edit',
      remove: 'Delete',
    },
  },
  hourTypes: {
    title: 'Create hour type',
    dialog: {
      create: 'Create hour type',
      edit: 'Edit hour type',
      remove: 'Remove hour type',
    },
  },
  employeeType: {
    title: 'Employee type | Employee types',
    dialog: {
      create: 'Create employee type',
      edit: 'Edit employee type',
      remove: 'Remove employee type',
    },
  },
  settings: {
    subscriptionTypes: 'Subscription Type | Subscription Types',
    locations: 'Branche | Branches',
    hourTypes: 'Hour Type | Hour Types',
    costTypes: 'Cost Type | Cost Types',
    projectTypes: 'Project Type | Project Types',
    ledgers: 'Ledger | Ledgers',
    vatCodes: 'VAT Code | VAT Codes',
    officeCosts: 'Office Costs | Office Costs',
    stamgegevens: 'Stamgegeven | Stamgegevens',
    employeeTypes: 'Employee Type | Employee Types',
    ratesManagement: 'Rates Management | Rates Management',
    calendarSettings: {
      title: 'Calendar settings',
      weekends: 'Weekends',
      first_day: 'Frst day of the week',
      calendar_default_view: 'Default view hour registration',
      calendar: 'Calendar',
      list: 'List',
      slot_duration: 'Register hour',
      end_with_today: 'End the week with today (see past 6 days)',
    },
  },
  user: {
    add: 'add {item}',
  },
  table: {
    found: '{total} {name} found',
    perPage: '{name} per page:',
    noData: 'No data available',
    filter: 'Filter on {name}:',
  },
  tableHeaders: {
    name: {
      title: 'Name',
      first: 'First Name',
      middle: 'Middle Name',
      last: 'Last Name',
    },
    age: {
      title: 'Age',
    },
    email: {
      title: 'Email',
    },
    phone: {
      title: 'Phone',
    },
    created_at: {
      title: 'Created at',
    },
    price: {
      title: 'Price',
    },
    code: {
      title: 'Code',
    },
  },
  navigation: {
    hourRegistration: 'Hour Registration',
    crm: 'CRM',
    clients: 'Clients',
    clientGroups: 'Client Groups',
    projects: 'Projects',
    subscriptions: 'Subscriptions',
    employees: 'Employees',
    billing: 'Billing',
    billingCost: 'Billing Cost',
    manualBilling: 'Manual Billing',
    accountancy: 'Accountancy',
    invoiceHistory: 'Invoice History',
    mailingList: 'Mailing List',
    debtors: 'Debtors',
    unreadMailPerUser: 'Unread Mail per User',
    outstandingAdvances: 'Outstanding Advances',
    reporting: 'Reporting',
    latourMatrix: 'Latour Matrix',
    clientFeeMatrix: 'Client Fee Matrix',
    revenuePrognosis: 'Revenue Prognosis',
    revenueBreakdown: 'Revenue Breakdown',
    timeSheet: 'Time Sheet',
    acquisitionMatrix: 'Acquisition Matrix',
    tenants: 'Tenant',
  },
  tenant: {
    title: 'Tenant | Tenants',
    dialog: {
      status: 'Tenant status',
      epona: 'Epona DMSFL Token',
      header: {
        general: 'Algemeen',
        naw: 'NAW',
        billing: 'Facturatie',
      },
    },
    status: {
      free: 'Free',
      active: 'Active',
      inactive: 'Inactive',
    },
  },
  client: {
    title: 'Client | Clients',
  },
  clientGroup: {
    title: 'Client Group | Client Groups',
  },
  project: {
    title: 'Project | Projects',
  },
  employee: {
    title: 'Employee | Employees',
  },
  subscription: {
    title: 'Subscription | Subscriptions',
  },
  profile: {
    title: 'Profile | Profiles',
    setting: 'Setting | Settings',
    mine: 'My Profile',
    standard: 'Standard Information',
    contact: {
      information: 'Contact Information',
      person: 'Contact person',
      number: 'Contact number',
    },
    dob: 'Date of Birth',
    name: 'Name',
    firstName: 'First name',
    middleName: 'Middle name',
    lastName: 'Last name',
    email: 'Email',
    phone: 'Phonenumber',
    website: 'Website',
    reportType: 'Report type',
    reportTag: 'Report tag',
    caseNumber: 'Case number',
    password: {
      title: 'New Password',
      forgot: 'Forgot your password?',
      current: 'Current password',
      confirm: 'Confirm password',
      change: 'Change password',
      save: 'Save password',
      saved: 'Password saved',
    },
    group: 'Group',
    login: 'Login as',
    address: 'Address',
    street: 'Street',
    housenumber: 'House number',
    addition: 'Addition',
    postcode: 'Zip code',
    city: 'City',
    budgetHours: 'Budget Hours',
    budgetMoney: 'Budget Money',
    code: 'Code',
    color: 'Color',
    invoiceName: 'Invoice name',
    calculationModel: 'Calculation Model',
    responsibleEmployee: 'Employee',
  },
  avatar: {
    title: 'Profile picture',
    change: 'Change picture',
  },
  general: {
    back: 'Back',
    search: 'Search | Search',
    cancel: 'cancel',
    yes: 'Yes',
    no: 'No',
  },
  time: {
    year: 'year | years',
    month: 'month | months',
    week: 'week | weeks',
    day: 'day | days',
    hour: 'hour | hours',
    minute: 'minute | minutes',
  },
  inbox: {
    title: '',
    menu: {
      inbox: 'Inbox',
      starred: 'Starred',
      sent: 'Sent',
      draft: 'Draft',
      create: 'New email',
      folders: 'Folders',
    },
    actions: {
      delete: 'Delete',
      reply: 'Reply',
      archive: 'Archive',
      sent: 'Sent',
    },
    contact: 'contact | contacts',
  },
  createClient: {
    vatNumber: 'Vat number',
    iban: 'Iban',
    chamberOfCommerce: 'Chamber of commerce',
    contactType: 'Contact type',
    debtor: 'This client is also a debtor',
  },
  createTentant: {
    logo: 'Logo',
    url: 'URL',
  },
  createProject: {
    calculationModel: 'Calculation model',
    budgetHours: 'Budget hours',
    budgetMoney: 'Budget money',
    status: 'Status',
    code: 'Code',
    debtor: 'Debtor',
  },
  form: {
    formFields: {
      avatar: 'Avatar',
      firstName: 'First name',
      LastName: 'Last name',
      middleName: 'Middle name',
      name: 'Name',
      invoiceName: 'Invoice name',
      website: 'Website',
      phone: 'Phone',
      username: 'Username',
      email: 'Email',
      password: 'Password',
      category: 'Category',
      confirmPassword: 'confirm password',
      code: 'Code',
      color: 'Project color',
      address: 'Address',
      street: 'Street',
      housenumber: 'Housenumber',
      addition: 'Addition',
      postcode: 'Zip code',
      city: 'Stad',
      createdAt: 'Created at',
      expert: 'Name Expert',
      calculationModel: 'Calculation model',
      calculationVat: 'BTW percentage',
      description: 'Description',
      time: 'Time',
      submissionDate: 'submission date',
      token: 'Token (optional)',
      country: {
        title: 'Land',
        nl: 'Netherlands',
        en: 'England',
        de: 'Germany',
      },
    },
    optional: 'Optioneel',
    fieldsRequiredText: 'Alle velden zijn verplicht',
  },
  languages: {
    title: 'Langauge',
    nl: 'Dutch',
    en: 'English',
    de: 'German',
  },
  createDialog: {
    header: {
      create: 'Create',
      overview: 'Overview',
      finished: 'Finished',
    },
    body: {
      title: {
        create: 'Create a new {role}',
        finished: '{role} is created',
        information: '{role} information',
        address: 'Debtor address',
        postAddress: 'Post address',
        officeAddress: 'Visitation Address',
        upload: 'Upload image',
      },
      description: {
        confirm: 'Confirm all information is correct for the new {role}',
        finished: 'View the created below {role}',
      },
      upload: 'Upload',
    },
    actions: {
      reset: 'Create another {role}',
    },
  },
  dialogOptions: {
    item: {
      client: 'Client',
      subscriptionType: 'Subscription type',
    },
    confirmation: 'Confirmation',
    delete: 'Are you sure you want to delete this {item}? | Are you sure you want to delete these {item}?',
    deleteMany: 'Are you sure you want to delete {item}?',
    lessonT: 'Are you sure you want to go to this {item}?',
    save: '{item} is saved',
    update: 'Are you sure you want to update this {item}? | Are you sure you want to update these {item}?',
    button: {
      delete: 'Delete',
      cancel: 'Cancel',
      ok: 'Ok',
    },
  },
  bugReport: {
    title: 'Bug',
    header: 'Report a bug',
    field: {
      title: 'Title',
      description: 'Description',
      screenshot: 'Screenshot',
    },
    create: {
      title: 'Add a title',
      description: 'Add a description.',
      descriptionExplanation: 'When did this bug occur? Which steps and /or actions did you go through? Which buttons did you click?',
      screenshot: 'Screenshot',
      submitBug: 'Submit bug',
      created: 'Your Bug report has been created.',
      downloadScreenshot: 'Download the image',
      uploadScreenshot: 'Upload the downloaded image in the link',
    },
    alert: {
      noIdSet: 'Monday Board Id or Group Id has not been set, cannot create a pulse',
      warning: 'Warning',
    },
    reportContent: {
      description: 'Description',
      localStorage: 'Local storage',
      stacktrace: 'Stacktrace',
      message: 'Message',
      file: 'File',
      line: 'Line',
      column: 'Column',
      error: 'Error',
    },
  },
  calculationModels: {
    fixed: 'Fixed',
    internal: 'Intern',
    costbased: 'Cost based',
  },
  errors: {
    loading: {
      description: 'There was a problem loading the required information.',
      suggestion: 'Please try again later.',
    },
    notFound: {
      description: 'The page you were looking for could not be found.',
      suggestion: '404',
      url: 'Please check the url for any typos',
      error: '404 ERROR.',
      back: 'go to home',
      oops: 'Oops :(',
      or: 'or',
      contact: 'contact us via',
    },
  },
  exact_online: {
    title: 'Exact Online',
    guid: 'Exact ID',
    log: 'Log',
  },
};
